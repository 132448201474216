/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import './ProInvoice.css'
import { useLoadRomsFormFieldListData } from '../../../../../../hooks/form.hooks'
import { useAuthContext } from '../../../../../../context/auth.context'
import { Spinner, Center, useTheme } from '@chakra-ui/react'
import GreneLogo from '../../../../../../assets/images/logo.png'
const ProformaInvoiceCafForm = ({
	formData,
	queueData,
	romsActivity,
	submittedForms,
}) => {
	const [cafData, setCafData] = useState(false)
	const [proInvoiceCafDataUpdated, setUpdatedCafData] = useState(null)

	const { mutate } = useLoadRomsFormFieldListData()
	const chakraTheme = useTheme()

	const {
		state: { authData },
	} = useAuthContext()

	const fetchFieldListData = () => {
		mutate(
			{
				fieldList: {
					organization_id: authData.organization_id,
					account_id: authData.account_id,
					workforce_id: authData.workforce_id,
					form_id: formData.form_id,
				},
				fieldValues: {
					form_id: formData.form_id,
					form_transaction_id: formData.form_transaction_id
						? formData.form_transaction_id
						: formData.data_form_transaction_id,
					start_from: 0,
					limit_value: 50,
				},
			},
			{
				onSuccess: async data => {
					let finalFields = data
					setCafData(false)
					setUpdatedCafData(finalFields)
				},
				onError: async err => {
					console.log('error occurred while loading fields', err)
				},
			}
		)
	}

	useEffect(() => {
		setCafData(true)
		fetchFieldListData()
	}, [])

	let ProInvoiceContentView = ''
	if (!!proInvoiceCafDataUpdated) {
		ProInvoiceContentView = (
				<div className='container'>
					<h1 className='text-center'>Proforma Invoice</h1>
					<div className='row'>
						<div className='col-md-7'>
							<p>GRENE ROBOTICS (INDIA) PRIVATE LIMITED</p>

							<div className='arow'>
								<input type='text' className='w70' value="Plot No. 437, Road No.20,"/>
							</div>
							<div className='arow'>
								<input type='text' className='w70' value=" Jubilee Hills,"/>
							</div>
							<div className='arow'>
								<input type='text' className='w70' value="Hyderabad - 500 033"/>
							</div>
							<div className='prow cin'>
								{' '}
								<span className='w20'> CIN: </span>{' '}
								<input type='text' className='w50' value="U45200TG2006PTC049030"/>{' '}
							</div>

							<div className='prow persons'>
								{' '}
								<span className='w30'> Contact Person : </span>{' '}
								<input type='text' className='w40' value={proInvoiceCafDataUpdated[333922].value} readOnly/>{' '}
							</div>

							<p>Bill To/Ship To</p>

							<div className='arow'>
								<input type='text' className='w70' value={proInvoiceCafDataUpdated[333193].value} readOnly/>
							</div>
							<div className='arow'>
								<input type='text' className='w70'/>
							</div>
							<div className='arow'>
								<input type='text' className='w70' />
							</div>
						</div>
						<div className='col-md-5'>
							{' '}
							<img src={GreneLogo} alt='' style={{marginBottom: '50px;'}} />
							<div className='prow'>
								{' '}
								<span className='w40'> Invoice # </span> <input type='text' value={proInvoiceCafDataUpdated[333201].value} readOnly/>{' '}
							</div>
							<div className='prow'>
								{' '}
								<span className='w40'> Invoice Date </span>
								<input type='text' value={proInvoiceCafDataUpdated[333202].value} readOnly/>{' '}
							</div>
							<div className='prow'>
								{' '}
								<span className='w40'> Client Name </span> <input type='text' value={proInvoiceCafDataUpdated[333203].value} readOnly/>
							</div>
							<div className='prow'>
								<span className='w40'>Client GSTIN</span> <input type='text' value={proInvoiceCafDataUpdated[333204].value} readOnly/>
							</div>
							<div className='prow'>
								<span className='w40'> Place of Supply</span> <input type='text' value={proInvoiceCafDataUpdated[333205].value} readOnly/>
							</div>
							<div className='prow'>
								<span className='w40'>Country of Supply</span> <input type='text' value={proInvoiceCafDataUpdated[333206].value} readOnly/>
							</div>
							<div className='prow'>
								<span className='w40'>Period</span> <input type='text' value={proInvoiceCafDataUpdated[333207].value} readOnly/>
							</div>
						</div>
					</div>

					<div className='invoice-table'>
						<table>
							<tr>
								<th>DESCRIPTION</th>
								<th>SAC Code</th>
								<th>QTY</th>
								<th>RATE INR</th>
								<th>AMOUNT INR</th>
							</tr>

							<tr>
								<td>{proInvoiceCafDataUpdated[333208].value}</td>
								<td>{proInvoiceCafDataUpdated[333209].value}</td>
								<td>{proInvoiceCafDataUpdated[333210].value}</td>
								<td>{proInvoiceCafDataUpdated[333211].value}</td>
								<td>{proInvoiceCafDataUpdated[333212].value}</td>
							</tr>
							<tr>
							<td>{proInvoiceCafDataUpdated[333997].value}</td>
								<td>{proInvoiceCafDataUpdated[333998].value}</td>
								<td>{proInvoiceCafDataUpdated[333999].value}</td>
								<td>{proInvoiceCafDataUpdated[334000].value}</td>
								<td>{proInvoiceCafDataUpdated[334001].value}</td>
							</tr>
							<tr>
								<td> </td>
								<td> </td>
								<td> </td>
								<td> TOTAL </td>
								<td>
									{' '}
									<input type='text' placeholder='0' value={proInvoiceCafDataUpdated[334002].value} readOnly/>{' '}
								</td>
							</tr>
							<tr>
								<td> </td>
								<td> </td>
								<td> </td>
								<td> TAXABLE VALUE </td>
								<td>
									{' '}
									<input type='text' placeholder='0' value={proInvoiceCafDataUpdated[334002].value} readOnly/>{' '}
								</td>
							</tr>
							<tr>
								<td> </td>
								<td> </td>
								<td> IGST @ </td>
								<td>
									{' '}
									<input type='text' placeholder='18%' value={proInvoiceCafDataUpdated[333213].value} readOnly/>{' '}
								</td>
								<td>
									{' '}
									<input type='text' placeholder='60000' value={proInvoiceCafDataUpdated[333214].value} readOnly/>{' '}
								</td>
							</tr>

							<tr>
								<th> </th>
								<td> </td>
								<th> </th>
								<th> TOTAL INVOICE VALUE </th>
								<th>
									<input type='text' placeholder='=333333+60000' value={proInvoiceCafDataUpdated[333215].value} readOnly/>{' '}
								</th>
							</tr>
						</table>
					</div>

					<div className='prow pb10'>
						{' '}
						<span className='w30 pr10'> Total Invoice Value (In Words): </span>{' '}
						<input type='text' className='w40' value={proInvoiceCafDataUpdated[333216].value} readOnly/>{' '}
					</div>
					<div className='prow'>
						{' '}
						<span className='w30 pr10'> Grene Robotics Company Details: </span>{' '}
					</div>

					<div className='prow pb10'>
						{' '}
						<span className='w30 pr10'> PAN </span>{' '}
						<input type='text' className='w40' value="AAKCS1013J"/>{' '}
					</div>
					<div className='prow'>
						{' '}
						<span className='w30 pr10'>
							{' '}
							DD/Cheques/NEFT/RTGS in favor of{' '}
						</span>{' '}
						<input
							type='text'
							className='w40'
							placeholder='M/s GRENE ROBOTICS (INDIA) PRIVATE LIMITED'
							value='M/s GRENE ROBOTICS (INDIA) PRIVATE LIMITED'
						/>{' '}
					</div>
					<div className='prow pb10'>
						{' '}
						<span className='w30 pr10'> Bank Details: </span>{' '}
					</div>
					<div className='prow pb10'>
						{' '}
						<span className='w30 pr10'> Beneficiary Name: </span>{' '}
						<input
							type='text'
							className='w40'
							placeholder='GRENE ROBOTICS (INDIA) PRIVATE LIMITED'
							value='GRENE ROBOTICS (INDIA) PRIVATE LIMITED'
						/>{' '}
					</div>
					<div className='prow pb10'>
						{' '}
						<span className='w30 pr10'> Beneficiary Bank: </span>{' '}
						<input type='text' className='w40' placeholder='AXIS BANK' value='AXIS BANK'/>{' '}
					</div>
					<div className='prow pb10'>
						{' '}
						<span className='w30 pr10'> Beneficiary A/c: </span>{' '}
						<input type='text' className='w40' placeholder='921020030631991' value='921020030631991' />{' '}
					</div>
					<div className='prow pb10'>
						{' '}
						<span className='w30 pr10'> IFSC Code: </span>{' '}
						<input type='text' className='w40' placeholder='UTIB0000515' value="UTIB0000515"/>{' '}
					</div>
					<div className='prow pb10'>
						{' '}
						<span className='w30 pr10'> GSTIN: </span>{' '}
						<input type='text' className='w40' placeholder='36AAKCS1013J1ZH' value="36AAKCS1013J1ZH"/>{' '}
					</div>

					<div className='text-center line20 ftr'>
						<div className='pb10'>
							{' '}
							<input
								type='text'
								className='w40'
								placeholder='Plot number 437, Road number 20 Jubilee Hills, Hyderabad-500033, Telangana, India'
								value='Plot number 437, Road number 20 Jubilee Hills, Hyderabad-500033, Telangana, India'
							/>{' '}
						</div>
						<div className='  pb10'>
							{' '}
							<span className='w30 pr10'> Website : </span>{' '}
							<input
								type='text'
								className='w40'
								placeholder='www.grenerobotics.com'
								value='www.grenerobotics.com'
							/>{' '}
						</div>
						<div className='  pb10'>
							{' '}
							<span className='w30 pr10'> Email Id : </span>{' '}
							<input
								type='text'
								className='w40'
								placeholder='Contact@grenerobotics.com'
								value="Contact@grenerobotics.com"
							/>{' '}
						</div>
						<div className='  pb10'>
							{' '}
							<span className='w30 pr10'>CIN : </span>{' '}
							<input
								type='text'
								className='w40'
								placeholder='U45200TG2006PTC049030'
								value="U45200TG2006PTC049030"
							/>{' '}
						</div>
						<div className='  pb10'>
							{' '}
							<span className='w30 pr10'>Phone No : </span>{' '}
							<input type='text' className='w40' placeholder='+91 7680090003' value={proInvoiceCafDataUpdated[333923].value} readOnly/>{' '}
						</div>
					</div>
				</div>
		)
	} else {
		ProInvoiceContentView = cafData ? (
			<div>
				<Center p={2} my={1}>
					<Spinner
						thickness='4px'
						speed='0.65s'
						emptyColor={chakraTheme.colors.secondary}
						color={chakraTheme.colors.brand[800]}
						size='md'
					/>
				</Center>
			</div>
		) : (
			<div style={{ marginTop: '200px', height: '300px', textAlign: 'center' }}>
				{' '}
				Proforma Invoice CAF Form not submitted yet.
			</div>
		)
	}
	return <div>{ProInvoiceContentView}</div>
}

export default ProformaInvoiceCafForm
